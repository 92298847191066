@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  overflow-x: hidden;
}

button:focus, .p-focus:focus, span:focus {
  outline: none !important;
  box-shadow: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.49);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.49);
  opacity: 0.49;
}

.App {
  text-align: center;
  background: transparent url('../public/background.jpg') 0% 0%;
  background-size: cover;
  display: flex;
  align-items: center;
}

.Container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  margin: auto;
  /* border-radius: 56px; */
  display: flex;
  padding-right: 4px;
}

.bot_card {
  /*scale: 1;*/
  /*transition: all 0.5s;*/
}

.bot_card:hover {
  /*scale: 1.3;*/
}

.bot_text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.Sidebar {
  width: 346px;
  height: 100%;
  background: #15151C 0% 0% no-repeat padding-box;
  padding: 26px 16px 28px 34px;
  color: white;
}

.logo-text {
  color: white;
  padding-left: 16px;
  font-weight: bold;
  font-size: 38px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #5D5D5D;
  padding-top: 14px;
  padding-bottom: 22px;
  justify-content: space-between;
}

.prompt {
  background-color: #15151C;
  border-radius: 20px;
  padding: 10px;
}

.prompt > Textarea {
  border: none;
  box-shadow: none !important;
}

.prompt > Textarea:focus {
  border: none !important;
  box-shadow: none;
}

.prompt-img {
  position: absolute;
  align-items: center;
  z-index: 1;
  bottom: 6px;
}

.msg {
  width: 100%;
  min-height: 60px;
  border-radius: 12px;
  text-align: left;
  background-color: #282c34;
  color: white;
  border: none;
  padding: 16px 8px;
  resize: none;
}

.desc {
  width: 100%;
  min-height: 60px;
  border-radius: 0;
  text-align: left;
  background-color: transparent;
  color: white;
  padding: 16px;
  resize: none;
  border: 1px solid #5d5d5d;
  font-size: 16px;
}

//textarea:hover, .msg:hover {
//  background-color: #242424;
//}

textarea:focus, msg:focus {
  outline: none;
  box-shadow: none;
}

.w-100 {
  width: 100%;
}

.typing {
  position: absolute;
  left: 0;
  bottom: -26px;
  font-size: 14px !important;

  .cs-typing-indicator__text {
    color: #B794F4 !important;
  }

  .cs-typing-indicator__dot {
    background-color: #B794F4 !important;
  }
}

.link {
  color: white !important;
  opacity: 0.59;
}

.link:hover {
  opacity: 1;
}

.cell {
  width: 13%;
  text-align: center !important;
  color: white !important;
}

.p-button {
  color: white;
  background: #6B46C1; // purple-600
  border: 1px solid #6B46C1;
  padding: 0.45rem 1.25rem;
  border-radius: 6px;
  transition: all 0.3s ease;
  outline: none !important;
}

.p-focus, .p-fileupload-choose, .p-button, .p-component {
  outline: none !important;
  border: none!important;
}

.p-button:hover {
  background: #805AD5; // purple-500
  border: 1px solid #805AD5;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: rgba(255, 255, 255, 0.48) !important;

}

.cs-message-input__content-editor {
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo-text-mobile {
  background: -webkit-linear-gradient(360deg, #8be3ff 0%, #6eb3fe 30%, #598ffe 35%, #7d4ffb 50%, #804dfb 65%, #ef84ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 20px;
  font-weight: bold;
  font-size: 2rem;
}

.wrapper-login {
  overflow: hidden;
  width: 480px;
  background: rgba(28, 28, 37, 0.85);
  padding: 30px;
  border-radius: 40px;

  button, input {
    background: rgba(28, 28, 37, 1) !important;
  }
}

@media (max-width: 576px) {
  .wrapper-login {
    width: 94%;
  }
}

.verify {
  margin-top: 1rem;
  color: white;
  text-align: left;
}

.verify > span {
  font-size: 1.5rem;
  font-weight: 700;
}

input[type="radio"] {
  display: none;
}

#signup:checked ~ .slider-tab {
  left: 50%;
}

#signup-mobile:checked ~ .slider-tab {
  left: 50%;
}

#signup:checked ~ label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}

#signup:checked ~ label.login {
  /* color: #000; */
  color: rgb(116, 116, 116);
}

#login:checked ~ label.signup {
  color: rgb(116, 116, 116);
}

#login:checked ~ label.login {
  cursor: default;
  user-select: none;
}

.form-container {
  width: 100%;
  overflow: hidden;
}

.form-container .form-inner {
  display: flex;
  width: 200%;
  align-items: center;
}

.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 12px;
}

.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border: 1px solid #5d5d5d;
  font-size: 16px;
  transition: all 0.3s ease;
  background: transparent;
  color: white;
  border-radius: 30px;
}

.form-inner form .field-no-radius input {
  border-radius: 0 !important;
  margin-top: 0 !important;
}

.form-inner form .field input:focus, .form-inner form textarea:focus {
  border-color: white;
}

.form-inner form .field input::placeholder, .form-inner form textarea::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

.form-inner form .pass-link {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.pass-link span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
  text-decoration: none;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
  cursor: pointer;
}

form .btn {
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

form .btn .btn-layer {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 0.4s ease;
  border-radius: 50px;
  border: solid 1px white;
}

form .btn:hover .btn-layer {
  left: 0;
}

form .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  color: #fff;
  padding-left: 0;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border: solid 1px white;
}

form button {
  transition: all 0.4s ease;
}

form {
  .btn input[type="submit"]:hover, .crypto-wallet:hover, button:hover {
    background-color: black !important;
    color: #e3e3e3 !important;
  }
}

.none {
  display: none;
}

.forgot-submit {
  text-align: left;
  color: white;
  display: none;
}

.forgot-submit.submitted {
  display: block;
}

.forgot-submit > div:first-child {
  font-size: 2rem;
  font-weight: 700;
}

.forgot-submit > div:nth-child(2) {
  font-weight: 700;
}

.file_upload_btn {
  width: 78%;
  display: flex;
}

.upload_btn {
  display: flex;
  width: 77%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.google-btn {
  width: 49%;
  border: solid 1px white;
  color: white;
  padding: 12px;
  border-radius: 50px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crypto-wallet {
  color: white;
  width: 49%;
  border: solid 1px white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.4s ease;

  .wallet-btn {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    font-size: 20px;
  }
}

.crypto-wallet > button {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  padding-left: 20px;
}

.lds-circle,
.lds-circle div {
  box-sizing: border-box;
  color: #e3e3e3;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin: 12px;
  background: currentColor;
  border-radius: 50%;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-circle-wallet > div {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-top: 4px;
  background: currentColor;
  border-radius: 50%;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.text-divider {
  color: rgba(255, 255, 255, 0.80);
  margin-bottom: 2px;
  margin-top: 22px;
  font-size: 18px;
}

.text-price {
  color: rgba(255, 255, 255, 0.70);
  margin-bottom: 20px;
  margin-top: 6px;
}

.navbar-menu {
  margin-left: 0;
}

.reactMarkDown {
  font-size: 16px;

  pre {
    font-size: 14px;
    padding-top: 0.5rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 16px;
    font-weight: 700;
  }

  h1:not(:first-child),
  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child),
  h6:not(:first-child) {
    margin-top: 1rem;
  }

  hr {
    margin-top: 1rem;
  }

  code {
    color: rgb(152, 195, 121);
    font-weight: bold;
    font-size: 15px !important;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ol,
  ul {
    margin-top: 1rem;
    margin-left: 1.5rem;

    ol,
    ul {
      margin-top: 0.5rem;
    }

    li {
      margin-top: 0.25rem;
    }
  }

  a {
    color: lightblue;
    text-decoration: underline;
  }

  p:not(:first-child) {
    margin-top: 1rem;
  }
}

.red-btn {
  background-color: #d9342b !important;// red-600
  border: 1px solid #d9342b !important; // red-600
}

.red-btn:hover {
  background-color: #ff3d32 !important; // red-500
  border: 1px solid #ff3d32 !important; // red-500
}

.chakra-badge {
  background-color: #d9342b !important;
  color: white !important;
}

.payment-menu {
  max-height: 130px;
  overflow-y: auto;
}

.custom-gradient {
  background-image: linear-gradient(280deg, rgb(183, 118, 255) 0%, rgb(93, 216, 190) 100%) !important;
}

.input-xai-image-container {
  position: relative;

  textarea {
    padding-right: 56px;
  }

  .upload-xai-image {
    position: absolute;
    right: 10px;
    bottom: 6px;
    opacity: 0.5;
    animation: smooth-appear 300ms ease-in-out;

    .custom-choose-btn {
      font-size: 32px;
    }

    .p-button {
      background: #282c34;
      padding: 0 0 0 6px;
      margin: 0;
    }
  }

  .select-voice {
    position: absolute;
    right: 10px;
    bottom: 10px;
    opacity: 0.5;
    animation: smooth-appear 300ms ease-in-out;
  }

  .send-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    opacity: 0.5;
    animation: smooth-appear 300ms ease-in-out;
  }

  @keyframes smooth-appear {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  .send-btn:hover {
    opacity: 1;
  }
}

.banner-content {
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  font-size: 26px;
  letter-spacing: 0;
  padding: 6px;


  .typewriter-cursor {
    display: inline-block;
    position: relative;
  }

  .typewriter-cursor::after {
    content: "";
    margin: auto;
    position: absolute;
    right: 2px;
    top: 7px;
    width: 3px;
    height: 26px;
    background-color: #fff;
    animation: cursor-animation 1s step-end infinite;
  }

  @keyframes cursor-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .banner-content {
    font-size: 20px;

    .typewriter-cursor::after {
      top: 6px;
      height: 20px;
    }

    .typewriter-cursor::after {
      width: 2px;
    }
  }
}

@media screen and (max-width: 450px) {
  .banner-content {
    font-size: 16px;
    height: 40px;

    .typewriter-cursor::after {
      top: 4px;
      height: 14px;
    }
  }
}

.model-img {
  height: 40px;
}

.token-img {
  height: 34px;
  margin-top: -4px;
  margin-bottom: -4px;
  border-radius: 50%;
}

.upload-mode-file {
  border: 2px solid #5d5d5d !important;
  padding-left: 4px;
  padding-right: 8px;
  line-height: 40px;

  .p-button {
    background: none;
    padding: 0 0 0 6px;
    margin: 0;
    color: #999;

    .p-button-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 200px;
    }

    .p-button-icon {
      display: none;
    }
  }
}

#messages {
  .citation-links {
    a {
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.token-amount-desktop {
  position: absolute;
  right: 8px;
  top: 110px;

  button {
    padding: 0 !important;
  }
}

.btns-mobile {
  margin-bottom: -36px;

  button {
    font-size: 12px !important;
    width: 100%;
    height: 32px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #222;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.maintenance-content {
  text-align: center;
  background-color: black;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 600px;
}

.maintenance-content h1 {
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}

.maintenance-details {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.maintenance-details p {
  font-size: 16px;
  color: white;
  margin: 10px 0;
}

.upload-avatar {
  position: absolute;
  bottom: 0;
  right: 2px;

  .p-button-label {
    display: none;
  }

  .p-button {
    font-size: 12px;
    padding: 6px;
    border-radius: 20px;
    border: 1px solid white !important;
  }

  .p-button-icon {
    margin-right: 0;
  }
}

.history-desc {
  margin-top: -6px;
}

.login-info-text {
  text-decoration: none !important;
  cursor: default !important;
}

.add-link:hover {
  background: #5d5d5d !important;
}

.chakra-toast {
  width: 350px;
}



@media screen and (max-width: 1280px) {
  .footer {
    p {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 991px) {
  .radio-model {
    font-size: 13px;
    margin-bottom: 150px;
  }
}

@media (max-width: 991px) {
  .messages-wrapper {
    height: 75vh !important;
  }
}

@media (max-width: 500px) {
  audio {
    max-width: 240px;
  }
}

